<template>
  <div class="flex flex-col">
    <div class="flex">
      <AlertsDeliveryMessengerChannel
        :open="rowChecked"
        :items="channelOptions"
        :alert-detail="{ name: alertName, entity: selectedAlertType }"
        :active-chat-option="selectedMessengerChat ? selectedMessengerChat : null"
        :in-place-search="true"
        @select="setMessengerData($event)"
        @close="openMessengerDropdown = false"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { truncate } from '@/composeables/filters';
import AlertsDeliveryMessengerChannel from '@/components/alerts/delivery/AlertsDeliveryMessengerChannels.vue';
import { useMapGetter } from '@/store/map-state';
import { getChannelName } from '@/composeables/chat';
import orderBy from 'lodash/orderBy';
import { ChatBubbleLeftRightIcon, PlusIcon } from '@heroicons/vue/20/solid';

const { requestChannels, channels, rejectedChannels } = useMapGetter();
const plusIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
             </svg>`;

const emit = defineEmits(['set-row', 'set-messenger-data']);
const props = defineProps({
  selectedAlertType: { type: Object, default: () => {} },
  alertName: { type: String, default: '' },
  checked: { type: Boolean, default: false },
  selectedMessengerChat: { type: Object, default: () => {} }
});

const openMessengerDropdown = ref(false);
const rowChecked = ref(props.checked);

// CHANNELS
const channelOptions = computed(() => {
  let defaultOption = {
    id: 'new',
    html: `<div class="py-0.5 flex items-center text-xs" style="margin-left: 2px"> ${plusIcon} <span class="mt-0.5 ml-0.5">Create New Chat</span></div>`
  };
  let options = [];
  let channelData = channels.value.filter(
    c => !rejectedChannels.value.includes(c.cid) && !requestChannels.value.includes(c.cid)
  );
  let allChannels = orderBy(
    channelData.filter(c => !requestChannels.value.includes(c.cid)),
    function (o) {
      return getChannelName(o);
    },
    ['asc']
  );
  allChannels.forEach(c => {
    let imageObj = '';
    let checkbox = `<div class="w-4 h-4 border mr-1 rounded-full ${
      props.selectedMessengerChat && props.selectedMessengerChat.id == c.id
        ? 'bg-blue-600 border-blue-600'
        : 'bg-gray-800 border-grid'
    }"></div>`;
    if (c.data.image) {
      imageObj = `<img class="w-5 h-5 rounded-full object-cover" src="${c.data.image}"/>`;
    } else {
      imageObj = `<div class="w-5 h-5 rounded-full text-gray-900 uupercase flex flex-col items-center justify-center ${
        c.data.background_color ? '' : 'bg-gray-500'
      }" style="background-color:${c.data.background_color}">${getChannelName(c) ? getChannelName(c)[0] : '<>'}</div>`;
    }
    options.push({
      id: c.id,
      text: getChannelName(c),
      html: `<div class="flex space-x-2 items-center justify-between h-5 pl-1">
                <div class="flex items-center">
                   ${imageObj}
                   <div class="break-words mx-2 text-gray-100 text-xs" >${truncate(getChannelName(c), 40)}</div>
                </div>
                <div>${checkbox}</div>
             </div>`
    });
  });
  options.unshift(defaultOption);
  return options;
});

// CHAT DATA
function setMessengerData(data) {
  let channelData = data.cid ? data : channels.value.find(x => x.id == data);
  if (channelData) {
    emit('set-messenger-data', {
      id: channelData.id,
      name: getChannelName(channelData)
    });
    rowChecked.value = true;
  }
}

watch(rowChecked, () => {
  emit('set-row', rowChecked.value);
});

watch(
  () => props.checked,
  first => {
    rowChecked.value = first;
  }
);
</script>
